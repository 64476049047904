import React from "react"
import OuterFloorMap from "./Map/OuterFloorMap"
import FirstFloor from "./Map/OuterFloorMap/FirstFloor"
import ThirdFloor from "./Map/OuterFloorMap/ThirdFloor"

export default ({floor, user, carrels}) => {
    switch (floor) {
        case 1:
            return (
                <OuterFloorMap>
                    <FirstFloor user={user} carrels={carrels}/>
                </OuterFloorMap>
            );

        case 3:
            return (
                <OuterFloorMap>
                    <ThirdFloor user={user} carrels={carrels}/>
                </OuterFloorMap>
            );

        default:
            return null;
    }
}