import React from "react"
import {Tabs} from "react-bulma-components"

export default ({floor, onChange}) => (
    <Tabs type="toggle" align="centered">
        <Tabs.Tab name="floor" active={(floor === 1)}
                  onClick={(e) => (floor !== 1 && onChange({target: {name: e.target.name, value: 1}}))}>1st
            Floor</Tabs.Tab>
        <Tabs.Tab name="floor" active={(floor === 3)}
                  onClick={(e) => (floor !== 3 && onChange({target: {name: e.target.name, value: 3}}))}>3rd
            Floor</Tabs.Tab>
    </Tabs>
)