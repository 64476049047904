import React, {useState, useEffect} from "react"
import ConfirmationModal from "./ConfirmationModal"
import * as d3 from "d3"
import useModal from "./useModal"

const styles = {
    default: "#40B0A6",
    selected: "#88CCEE",
};

const useSvgMap = (user, carrels) => {
    const [isSelected, setIsSelected] = useState(null);
    const [message, setMessage] = useState(null);
    const {isShowing, toggle, isError} = useModal();

    useEffect(() => {
        d3.selectAll(".AVAILABLE").attr("fill", styles.default).on("mouseover", function (d) {
            d3.select(this).attr("fill", styles.selected);
            d3.select(".seat-num").text((this.id));
        }).on("mouseout", function (d) {
            d3.select(this).attr("fill", styles.default);
        }).on("click", function (d) {
            setIsSelected(this.id);
            setMessage(d3.select(this).attr("message"));
            toggle();
        });

        d3.selectAll(".TAKEN").attr("fill", "url(#diagonalHatch)").on("mouseover", function (d) {
            d3.select(".seat-num").text((this.id));

            if (carrels && carrels[(this.id - 1)].studentNetId) {
                d3.select(".seat-num").text(carrels[(this.id - 1)].studentNetId);
            }
        }).on("mouseout", function (d) {
            d3.select(this).attr("fill", "url(#diagonalHatch)");
        });
    });

    return (
        <ConfirmationModal isShowing={isShowing} hide={toggle} selected={isSelected} user={user} message={message}/>
    )
};

export default useSvgMap;