import React from "react"
import styles from "./OuterFloorMap/Map.module.scss"
import useSvgMap from "./OuterFloorMap/useSvgMap"
import Defs from "./OuterFloorMap/Defs"

function OuterFloorMap(props) {
    useSvgMap();

    return (
        <div className={styles["svg-container"]}>
            <svg className={styles.svg} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-400 0 1400 900">
                <Defs/>
                {props.children}
                <text className="seat-num" x="-275" y="100"
                      style={{fontWeight: "bold", fill: "#3273dc", fillOpacity: "1", fontSize: 84}}/>

                <g className="move-up is-clickable" transform="translate(105 -42)">
                    <path id="path1824" fill="#fff" opacity="1"
                          d="M607.628 768.111h51.404a9.052 9.052 0 019.072 9.072v60.476h-69.548v-60.476a9.052 9.052 0 019.072-9.072z"/>
                    <rect id="rect1858" className="up-arrow" width="10.691" height="33.676" x="-120.146" y="1004.341"
                          fill="#707070" opacity="1" ry="5.345" transform="rotate(-45.072)"/>
                    <rect id="move-up" className="up-arrow" width="10.691" height="33.676" x="1004.447" y="109.771"
                          fill="#707070" opacity="1" ry="5.345" transform="rotate(44.928)"/>
                </g>

                <g className="move-down is-clickable" id="move-down" transform="translate(105 -42)">
                    <path id="path1830" fill="#fff" opacity="1"
                          d="M598.556 838.717h69.548v60.476a9.052 9.052 0 01-9.072 9.072h-51.404a9.052 9.052 0 01-9.072-9.072V847.79v-9.072z"/>
                    <rect id="rect1854" className="down-arrow" width="10.691" height="33.676" x="-1076.445" y="-181.71"
                          fill="#707070" opacity="1" ry="5.345" transform="rotate(-135.072)"/>
                    <rect id="rect1856" className="down-arrow" width="10.691" height="33.676" x="-182.026" y="1042.876"
                          fill="#707070" opacity="1" ry="5.345" transform="rotate(-45.072)"/>
                </g>
                <g className="zoom-in is-clickable" transform="translate(320 150)">
                    <path fill="#fff" fillOpacity="1" stroke="none" strokeOpacity="1" strokeWidth="0.3"
                          d="M471.8 576h51.4c5 0 9.1 4.1 9.1 9.1v60.5h-69.5v-60.5c0-5 4-9 9-9z" opacity="1"/>
                    <rect className="plus" width="10.7" height="33.7" x="492.2" y="594" fill="#707070" fillOpacity="1"
                          stroke="none" strokeOpacity="1" strokeWidth="0.3" opacity="1" ry="5.3"/>
                    <rect className="plus" width="10.7" height="33.7" x="605.5" y="-514.4" fill="#707070"
                          fillOpacity="1" stroke="none" strokeOpacity="1" strokeWidth="0.3" opacity="1" ry="5.3"
                          transform="rotate(90)"/>
                </g>
                <g className="zoom-out is-clickable" transform="translate(320 150)">
                    <path fill="#fff" fillOpacity="1" stroke="none" strokeOpacity="1" strokeWidth="0.3"
                          d="M462.8 646.7h69.5v60.5c0 5-4 9-9 9h-51.5c-5 0-9-4-9-9v-60.5z" opacity="1"/>
                    <rect className="minus" width="10.7" height="33.7" x="676.1" y="-514.4" fill="#707070"
                          fillOpacity="1" stroke="none" strokeOpacity="1" strokeWidth="0.3" opacity="1" ry="5.3"
                          transform="rotate(90)"/>
                </g>

                <g className="move-right is-clickable" id="move-right" transform="translate(106 -42)">
                    <path id="path1814" fill="#fff" opacity="1"
                          d="M766.378 768.111h51.404a9.052 9.052 0 019.072 9.072v60.476h-69.548v-60.476a9.052 9.052 0 019.072-9.072z"/>
                    <rect id="rect1816" className="right-arrow" width="10.691" height="33.676" x="-17.074" y="-1140.742"
                          fill="#707070" opacity="1" ry="5.345" transform="scale(1 -1) rotate(45.587)"/>
                    <rect id="rect1818" className="right-arrow" width="10.691" height="33.676" x="-1140.636" y="6.699"
                          fill="#707070" opacity="1" ry="5.345" transform="scale(-1 1) rotate(-44.413)"/>
                </g>

                <g className="move-left is-clickable" id="move-left" transform="translate(106 -42)">
                    <path id="path1820" fill="#fff" opacity="1"
                          d="M757.306 838.717h69.548v60.476a9.052 9.052 0 01-9.072 9.072h-51.404a9.052 9.052 0 01-9.072-9.072V847.79v-9.072z"/>
                    <rect id="rect1850" className="left-arrow" width="10.691" height="33.676" x="1165.124" y="-79.815"
                          fill="#707070" opacity="1" ry="5.345" transform="scale(1 -1) rotate(-44.413)"/>
                    <rect id="rect1852" className="left-arrow" width="10.691" height="33.676" x="-80.131" y="-1198.694"
                          fill="#707070" opacity="1" ry="5.345" transform="scale(1 -1) rotate(45.587)"/>
                </g>
            </svg>
        </div>
    )
}

export default OuterFloorMap