import React from "react"
import {connect} from "react-redux"
import {Router} from "@reach/router"
import StudentRoute from "../routes/student"
import NotFound from "../components/404"
import IndexRoute from "../routes/student/index"
import {hasJWT, login} from "../actions/authentication"

class IndexPage extends React.Component {
    componentDidMount() {
        if (localStorage && localStorage.getItem(process.env.FEATHERSJS_STORAGE_KEY)) {
            this.props.hasJWT();
        } else {
            this.props.login();
        }
    }

    render() {
        const {reduxState: {authentication: authenticationState}} = this.props;

        return (
            <Router>
                <NotFound default/>
                <StudentRoute path="/" component={IndexRoute} authenticationState={authenticationState}
                              user={authenticationState.user}/>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    return {
        reduxState: state
    };
}

export default connect(mapStateToProps, {login, hasJWT})(IndexPage)