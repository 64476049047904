import React from "react"

function Defs() {
    return (
        <defs>
            <filter id="d" colorInterpolationFilters="sRGB">
                <feFlood floodColor="#000" floodOpacity="0.427" result="flood"/>
                <feComposite in="flood" in2="SourceGraphic" operator="in" result="composite1"/>
                <feGaussianBlur in="composite1" result="blur" stdDeviation="0"/>
                <feOffset dx="0.262" dy="2.6" result="offset"/>
                <feComposite in="SourceGraphic" in2="offset" operator="over" result="composite2"/>
            </filter>
            <filter id="c" colorInterpolationFilters="sRGB">
                <feFlood floodColor="#000" floodOpacity="0.427" result="flood"/>
                <feComposite in="flood" in2="SourceGraphic" operator="in" result="composite1"/>
                <feGaussianBlur in="composite1" result="blur" stdDeviation="0"/>
                <feOffset dx="0.262" dy="2.6" result="offset"/>
                <feComposite in="SourceGraphic" in2="offset" operator="over" result="composite2"/>
            </filter>
            <filter id="b" colorInterpolationFilters="sRGB">
                <feFlood floodColor="#000" floodOpacity="0.427" result="flood"/>
                <feComposite in="flood" in2="SourceGraphic" operator="in" result="composite1"/>
                <feGaussianBlur in="composite1" result="blur" stdDeviation="0"/>
                <feOffset dx="0.262" dy="2.6" result="offset"/>
                <feComposite in="SourceGraphic" in2="offset" operator="over" result="composite2"/>
            </filter>
            <filter id="a" colorInterpolationFilters="sRGB">
                <feFlood floodColor="#000" floodOpacity="0.498" result="flood"/>
                <feComposite in="flood" in2="SourceGraphic" operator="in" result="composite1"/>
                <feGaussianBlur in="composite1" result="blur" stdDeviation="0"/>
                <feOffset dx="1.309" dy="7.9" result="offset"/>
                <feComposite in="SourceGraphic" in2="offset" operator="over" result="composite2"/>
            </filter>
            <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                <rect width="100%" height="100%" x="0" fill="#E1BE6A"></rect>
                <path d="M-1,1 l2,-2M0,4 l4,-4M3,5 l2,-2" style={{stroke: "black", strokeWidth: "1" }}></path>
            </pattern>
        </defs>
    )
}

export default Defs
