import React from "react";
import useSVGCarrels from "../useSVGCarrels"

export default ({user, carrels}) => {
    const Modal = useSVGCarrels(user, carrels);

    return (
        (carrels.length > 0) ? <g className="all" fillOpacity="1" strokeOpacity="1" transform="">
            <path
                className="floor-path"
                id="path1463-3"
                fill="#fff"
                fillOpacity="1"
                stroke="#dfdfdf"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="4"
                d="M-42.98 159.459l-.723 239.642h-36.09V478.5l36.451-.36-1.804 107.188 117.294-1.082-.36 133.896 109.715-1.443v-58.106l55.94-.36-.721-75.791 147.61.36.361 11.55h33.565l-.722-90.588-32.482.722.361-344.666z"
                filter="url(#a)"
                opacity="1"
                transform="matrix(1.23798 0 0 1.23798 107.557 -80.008)"
            />
            <g
                id="floor-1-2"
                transform="matrix(1.23798 0 0 1.23798 -1165.506 276.872)"
            >
                <g
                    id="section-6"
                    fill="#c6e48c"
                    fillOpacity="0.996"
                    stroke="#040404"
                    strokeOpacity="0.418"
                >
                    <g id="g1773-6" transform="translate(923.381 34.137)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1835"
                            x="136.098"
                            y="197.496"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1833"
                                x="136.098"
                                y="197.496"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="136.098"
                                    y="197.496"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1831"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    76
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="76"
                            className={`is-clickable ${((carrels[75].status === "RESERVED") || (carrels[75].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="130.776"
                            y="191.262"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1771-5"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M148.195 200.477l-17.555.06"
                        />
                    </g>
                    <g id="g1779-3" transform="translate(942.079 34.137)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1841"
                            x="136.14"
                            y="197.461"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1839"
                                x="136.14"
                                y="197.461"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="136.14"
                                    y="197.461"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1837"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    75
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="75"
                            className={`is-clickable ${((carrels[74].status === "RESERVED") || (carrels[74].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="130.776"
                            y="191.262"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1777-6"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M148.195 200.477l-17.555.06"
                        />
                    </g>
                    <g id="g1785-2" transform="translate(960.776 34.137)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1847"
                            x="136.083"
                            y="197.5"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1845" x="136.083" y="197.5" strokeWidth="0.214">
                                <tspan
                                    x="136.083"
                                    y="197.5"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1843"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    74
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="74"
                            className={`is-clickable ${((carrels[73].status === "RESERVED") || (carrels[73].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="130.776"
                            y="191.262"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1783-1"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M148.195 200.477l-17.555.06"
                        />
                    </g>
                    <g id="g1791-2" transform="translate(979.473 34.137)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1853"
                            x="136.152"
                            y="197.5"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1851" x="136.152" y="197.5" strokeWidth="0.214">
                                <tspan
                                    x="136.152"
                                    y="197.5"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1849"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    73
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="73"
                            className={`is-clickable ${((carrels[72].status === "RESERVED") || (carrels[72].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="130.776"
                            y="191.262"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1789-0"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M148.195 200.477l-17.555.06"
                        />
                    </g>
                    <g id="g1767-9" transform="translate(954.732 146.787)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1859"
                            x="160.483"
                            y="50.444"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1857"
                                x="160.483"
                                y="50.444"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="160.483"
                                    y="50.444"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1855"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    65
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1865"
                            x="160.464"
                            y="59.759"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1863"
                                x="160.464"
                                y="59.759"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="160.464"
                                    y="59.759"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1861"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    72
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="72"
                            className={`is-clickable ${((carrels[71].status === "RESERVED") || (carrels[71].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.114"
                            y="53.483"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="65"
                            className={`is-clickable ${((carrels[64].status === "RESERVED") || (carrels[64].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.098"
                            y="44.21"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1765-0"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M172.516 53.425l-17.554.06"
                        />
                    </g>
                    <g id="g1759-6" transform="translate(936.035 146.787)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1871"
                            x="160.559"
                            y="59.727"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1869"
                                x="160.559"
                                y="59.727"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="160.559"
                                    y="59.727"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1867"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    71
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1877"
                            x="160.44"
                            y="50.444"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1875" x="160.44" y="50.444" strokeWidth="0.214">
                                <tspan
                                    x="160.44"
                                    y="50.444"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1873"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    66
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="71"
                            className={`is-clickable ${((carrels[70].status === "RESERVED") || (carrels[70].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.103"
                            y="53.483"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="66"
                            className={`is-clickable ${((carrels[65].status === "RESERVED") || (carrels[65].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.098"
                            y="44.21"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1757-1"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M172.52 53.425l-17.555.06"
                        />
                    </g>
                    <g id="g1751-8" transform="translate(917.338 146.787)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1883"
                            x="160.463"
                            y="50.444"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1881"
                                x="160.463"
                                y="50.444"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="160.463"
                                    y="50.444"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1879"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    67
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1889"
                            x="160.466"
                            y="59.719"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1887"
                                x="160.466"
                                y="59.719"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="160.466"
                                    y="59.719"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1885"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    70
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="70"
                            className={`is-clickable ${((carrels[69].status === "RESERVED") || (carrels[69].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.114"
                            y="53.483"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="67"
                            className={`is-clickable ${((carrels[66].status === "RESERVED") || (carrels[66].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.098"
                            y="44.21"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1749-2"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M172.516 53.425l-17.554.06"
                        />
                    </g>
                    <g id="g1743-0" transform="translate(898.64 146.787)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1895"
                            x="160.454"
                            y="50.448"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1893"
                                x="160.454"
                                y="50.448"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="160.454"
                                    y="50.448"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1891"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    68
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1901"
                            x="160.487"
                            y="59.719"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1899"
                                x="160.487"
                                y="59.719"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="160.487"
                                    y="59.719"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1897"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    69
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="69"
                            className={`is-clickable ${((carrels[68].status === "RESERVED") || (carrels[68].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.114"
                            y="53.483"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="68"
                            className={`is-clickable ${((carrels[67].status === "RESERVED") || (carrels[67].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.098"
                            y="44.21"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1741-7"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M172.516 53.425l-17.554.06"
                        />
                    </g>
                    <g id="g1815-5" transform="translate(949.229 -26.941)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1907"
                            x="166.055"
                            y="198.162"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1905"
                                x="166.055"
                                y="198.162"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="166.055"
                                    y="198.162"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1903"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    64
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="64"
                            className={`is-clickable ${((carrels[63].status === "RESERVED") || (carrels[63].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="160.727"
                            y="191.928"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1813-2"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M178.13 191.87l-17.555.06"
                        />
                    </g>
                    <g id="g1809-2" transform="translate(930.708 -26.941)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1913"
                            x="166.124"
                            y="198.166"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1911"
                                x="166.124"
                                y="198.166"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="166.124"
                                    y="198.166"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1909"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    63
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="63"
                            className={`is-clickable ${((carrels[62].status === "RESERVED") || (carrels[62].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="160.727"
                            y="191.928"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1807-9"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M178.13 191.87l-17.555.06"
                        />
                    </g>
                    <g id="g1803-7" transform="translate(912.187 -26.941)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1919"
                            x="166.098"
                            y="198.166"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1917"
                                x="166.098"
                                y="198.166"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="166.098"
                                    y="198.166"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1915"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    62
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="62"
                            className={`is-clickable ${((carrels[61].status === "RESERVED") || (carrels[61].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="160.727"
                            y="191.928"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1801-6"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M178.13 191.87l-17.555.06"
                        />
                    </g>
                    <g id="g1797-1" transform="translate(893.666 -26.941)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1925"
                            x="166.204"
                            y="198.162"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1923"
                                x="166.204"
                                y="198.162"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="166.204"
                                    y="198.162"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1921"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    61
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="61"
                            className={`is-clickable ${((carrels[60].status === "RESERVED") || (carrels[60].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="160.727"
                            y="191.928"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1795-9"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M178.13 191.87l-17.555.06"
                        />
                    </g>
                </g>
                <g
                    id="section-5-3"
                    fill="#c6e48c"
                    fillOpacity="0.996"
                    stroke="#040404"
                    strokeOpacity="0.418"
                >
                    <g id="g1735-1" transform="translate(841.017 36.404)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1775"
                            x="160.487"
                            y="59.719"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1773"
                                x="160.487"
                                y="59.719"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="160.487"
                                    y="59.719"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1771"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    60
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1781"
                            x="160.408"
                            y="50.447"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1779"
                                x="160.408"
                                y="50.447"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="160.408"
                                    y="50.447"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1777"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    59
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="60"
                            className={`is-clickable ${((carrels[59].status === "RESERVED") || (carrels[59].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            message="This carrel is close to equipment that may be loud"
                            width="17.278"
                            height="9.217"
                            x="155.114"
                            y="53.483"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="59"
                            className={`is-clickable ${((carrels[58].status === "RESERVED") || (carrels[58].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.098"
                            y="44.21"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1733-7"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M172.516 53.425l-17.554.06"
                        />
                    </g>
                    <g id="g1727-8" transform="translate(841.017 -7.561)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1787"
                            x="160.4"
                            y="50.409"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1785" x="160.4" y="50.409" strokeWidth="0.214">
                                <tspan
                                    x="160.4"
                                    y="50.409"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1783"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    57
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1793"
                            x="160.408"
                            y="59.72"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1791" x="160.408" y="59.72" strokeWidth="0.214">
                                <tspan
                                    x="160.408"
                                    y="59.72"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1789"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    58
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="58"
                            className={`is-clickable ${((carrels[57].status === "RESERVED") || (carrels[57].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.114"
                            y="53.483"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="57"
                            className={`is-clickable ${((carrels[56].status === "RESERVED") || (carrels[56].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.098"
                            y="44.21"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1725-0"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M172.516 53.425l-17.554.06"
                        />
                    </g>
                    <g id="g1719-3" transform="translate(841.017 -51.527)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1799"
                            x="160.42"
                            y="50.409"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1797" x="160.42" y="50.409" strokeWidth="0.214">
                                <tspan
                                    x="160.42"
                                    y="50.409"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1795"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    55
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1805"
                            x="160.395"
                            y="59.717"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1803"
                                x="160.395"
                                y="59.717"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="160.395"
                                    y="59.717"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1801"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    56
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="56"
                            className={`is-clickable ${((carrels[55].status === "RESERVED") || (carrels[55].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.114"
                            y="53.483"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="55"
                            className={`is-clickable ${((carrels[54].status === "RESERVED") || (carrels[54].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.098"
                            y="44.21"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1717-0"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M172.516 53.425l-17.554.06"
                        />
                    </g>
                    <g id="g1711-6" transform="translate(841.017 -95.493)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1811"
                            x="160.38"
                            y="59.682"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1809" x="160.38" y="59.682" strokeWidth="0.214">
                                <tspan
                                    x="160.38"
                                    y="59.682"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1807"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    54
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1817"
                            x="160.432"
                            y="50.448"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1815"
                                x="160.432"
                                y="50.448"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="160.432"
                                    y="50.448"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1813"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    53
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="54"
                            className={`is-clickable ${((carrels[53].status === "RESERVED") || (carrels[53].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.114"
                            y="53.483"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="53"
                            className={`is-clickable ${((carrels[52].status === "RESERVED") || (carrels[52].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.098"
                            y="44.21"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1709-0"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M172.516 53.425l-17.554.06"
                        />
                    </g>
                    <g id="g1703-6" transform="translate(841.017 -139.459)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1823"
                            x="160.422"
                            y="59.72"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1821" x="160.422" y="59.72" strokeWidth="0.214">
                                <tspan
                                    x="160.422"
                                    y="59.72"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1819"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    52
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1829"
                            x="160.512"
                            y="50.415"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1827"
                                x="160.512"
                                y="50.415"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="160.512"
                                    y="50.415"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1825"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    51
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="52"
                            className={`is-clickable ${((carrels[51].status === "RESERVED") || (carrels[51].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.114"
                            y="53.483"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="51"
                            className={`is-clickable ${((carrels[50].status === "RESERVED") || (carrels[50].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="155.098"
                            y="44.21"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1701-5"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M172.516 53.425l-17.554.06"
                        />
                    </g>
                </g>
                <g
                    id="section-4-4"
                    fill="#c6e48c"
                    fillOpacity="0.996"
                    stroke="#040404"
                    strokeOpacity="0.418"
                >
                    <g id="g1695-7" transform="translate(920.56 -166.473)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1715"
                            x="-56.57"
                            y="145.593"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1713"
                                x="-56.57"
                                y="145.593"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.57"
                                    y="145.593"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1711"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    49
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1721"
                            x="-56.666"
                            y="136.318"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1719"
                                x="-56.666"
                                y="136.318"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.666"
                                    y="136.318"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1717"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    50
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="50"
                            className={`is-clickable ${((carrels[49].status === "RESERVED") || (carrels[49].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.127"
                            y="130.415"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="49"
                            className={`is-clickable ${((carrels[48].status === "RESERVED") || (carrels[48].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.11"
                            y="139.688"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1693-6"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M139.352 44.587l-.05 17.555"
                        />
                    </g>
                    <g id="g1687-9" transform="translate(964.348 -166.473)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1727"
                            x="-56.578"
                            y="145.592"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1725"
                                x="-56.578"
                                y="145.592"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.578"
                                    y="145.592"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1723"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    47
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1733"
                            x="-56.545"
                            y="136.169"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1731"
                                x="-56.545"
                                y="136.169"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.545"
                                    y="136.169"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1729"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    48
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="48"
                            className={`is-clickable ${((carrels[47].status === "RESERVED") || (carrels[47].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.128"
                            y="130.264"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="47"
                            className={`is-clickable ${((carrels[46].status === "RESERVED") || (carrels[46].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.11"
                            y="139.688"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1685-4"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M139.352 44.587l-.05 17.555"
                        />
                    </g>
                    <g id="g1679-5" transform="translate(1008.137 -166.473)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1739"
                            x="-56.559"
                            y="136.316"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1737"
                                x="-56.559"
                                y="136.316"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.559"
                                    y="136.316"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1735"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    46
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1745"
                            x="-56.558"
                            y="145.553"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1743"
                                x="-56.558"
                                y="145.553"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.558"
                                    y="145.553"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1741"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    45
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="46"
                            className={`is-clickable ${((carrels[45].status === "RESERVED") || (carrels[45].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.127"
                            y="130.415"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="45"
                            className={`is-clickable ${((carrels[44].status === "RESERVED") || (carrels[44].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.11"
                            y="139.688"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1677-4"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M139.352 44.587l-.05 17.555"
                        />
                    </g>
                    <g id="g1671-7" transform="translate(1051.925 -166.473)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1751"
                            x="-56.574"
                            y="136.32"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1749"
                                x="-56.574"
                                y="136.32"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.574"
                                    y="136.32"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1747"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    44
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1757"
                            x="-56.546"
                            y="145.592"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1755"
                                x="-56.546"
                                y="145.592"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.546"
                                    y="145.592"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1753"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    43
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="44"
                            className={`is-clickable ${((carrels[43].status === "RESERVED") || (carrels[43].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.127"
                            y="130.415"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="43"
                            className={`is-clickable ${((carrels[42].status === "RESERVED") || (carrels[42].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.11"
                            y="139.688"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1669-3"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M139.352 44.587l-.05 17.555"
                        />
                    </g>
                    <g id="g1663-0" transform="translate(1095.714 -166.473)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1763"
                            x="-56.531"
                            y="136.358"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1761"
                                x="-56.531"
                                y="136.358"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.531"
                                    y="136.358"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1759"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    42
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1769"
                            x="-56.466"
                            y="145.598"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1767"
                                x="-56.466"
                                y="145.598"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.466"
                                    y="145.598"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1765"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    41
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            className={`is-clickable ${((carrels[41].status === "RESERVED") || (carrels[41].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            id="42"
                            width="17.278"
                            height="9.217"
                            x="-61.127"
                            y="130.415"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            className={`is-clickable ${((carrels[40].status === "RESERVED") || (carrels[40].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            id="41"
                            width="17.278"
                            height="9.217"
                            x="-61.11"
                            y="139.688"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1661-6"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M139.352 44.587l-.05 17.555"
                        />
                    </g>
                </g>
                <g
                    id="section-3"
                    fill="#c6e48c"
                    fillOpacity="0.996"
                    stroke="#040404"
                    strokeOpacity="0.418"
                >
                    <g id="g1631-8" transform="translate(1157.918 -111.704)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1571"
                            x="-56.713"
                            y="145.451"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1569"
                                x="-56.713"
                                y="145.451"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.713"
                                    y="145.451"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1567"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    36
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1577"
                            x="-56.649"
                            y="136.32"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1575"
                                x="-56.649"
                                y="136.32"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.649"
                                    y="136.32"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1573"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    37
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="36"
                            className={`is-clickable ${((carrels[35].status === "RESERVED") || (carrels[35].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.111"
                            y="139.547"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="37"
                            className={`is-clickable ${((carrels[36].status === "RESERVED") || (carrels[36].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.127"
                            y="130.415"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1629-3"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M139.352 44.587l-.05 17.555"
                        />
                    </g>
                    <g id="g1639-1" transform="translate(1157.918 -130.246)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1583"
                            x="-56.658"
                            y="136.32"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1581"
                                x="-56.658"
                                y="136.32"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.658"
                                    y="136.32"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1579"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    38
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1589"
                            x="-56.67"
                            y="145.592"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1587"
                                x="-56.67"
                                y="145.592"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.67"
                                    y="145.592"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1585"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    35
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="35"
                            className={`is-clickable ${((carrels[34].status === "RESERVED") || (carrels[34].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.11"
                            y="139.688"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="38"
                            className={`is-clickable ${((carrels[37].status === "RESERVED") || (carrels[37].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.127"
                            y="130.415"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1637-2"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M139.352 44.587l-.05 17.555"
                        />
                    </g>
                    <g id="g1647-0" transform="translate(1157.918 -148.788)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1595"
                            x="-56.641"
                            y="136.32"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1593"
                                x="-56.641"
                                y="136.32"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.641"
                                    y="136.32"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1591"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    39
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1601"
                            x="-56.728"
                            y="145.589"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1599"
                                x="-56.728"
                                y="145.589"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.728"
                                    y="145.589"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1597"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    34
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="34"
                            className={`is-clickable ${((carrels[33].status === "RESERVED") || (carrels[33].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.11"
                            y="139.685"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="39"
                            className={`is-clickable ${((carrels[38].status === "RESERVED") || (carrels[38].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.127"
                            y="130.415"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1645-9"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M139.352 44.587l-.05 17.555"
                        />
                    </g>
                    <g id="g1655-2" transform="translate(1157.918 -167.33)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1607"
                            x="-56.529"
                            y="136.318"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1605"
                                x="-56.529"
                                y="136.318"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.529"
                                    y="136.318"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1603"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    40
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1613"
                            x="-56.659"
                            y="145.592"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1611"
                                x="-56.659"
                                y="145.592"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.659"
                                    y="145.592"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1609"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    33
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="33"
                            className={`is-clickable ${((carrels[32].status === "RESERVED") || (carrels[32].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.11"
                            y="139.688"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="40"
                            className={`is-clickable ${((carrels[39].status === "RESERVED") || (carrels[39].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.127"
                            y="130.415"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1653-4"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M139.352 44.587l-.05 17.555"
                        />
                    </g>
                    <g id="g1599-9" transform="translate(1196.547 -111.175)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1619"
                            x="-56.692"
                            y="145.592"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1617"
                                x="-56.692"
                                y="145.592"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.692"
                                    y="145.592"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1615"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    28
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1625"
                            x="-56.634"
                            y="136.322"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1623"
                                x="-56.634"
                                y="136.322"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.634"
                                    y="136.322"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1621"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    29
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="28"
                            className={`is-clickable ${((carrels[27].status === "RESERVED") || (carrels[27].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.11"
                            y="139.688"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="29"
                            className={`is-clickable ${((carrels[28].status === "RESERVED") || (carrels[28].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.127"
                            y="130.415"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1597-4"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M139.352 44.587l-.05 17.555"
                        />
                    </g>
                    <g id="g1607-8" transform="translate(1196.547 -129.717)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1631"
                            x="-56.683"
                            y="145.631"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1629"
                                x="-56.683"
                                y="145.631"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.683"
                                    y="145.631"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1627"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    27
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1637"
                            x="-56.641"
                            y="136.558"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1635"
                                x="-56.641"
                                y="136.558"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.641"
                                    y="136.558"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1633"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    30
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="27"
                            className={`is-clickable ${((carrels[26].status === "RESERVED") || (carrels[26].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.11"
                            y="139.688"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="30"
                            className={`is-clickable ${((carrels[29].status === "RESERVED") || (carrels[29].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.125"
                            y="130.653"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1605-7"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M139.352 44.587l-.05 17.555"
                        />
                    </g>
                    <g id="g1615-2" transform="translate(1196.547 -148.788)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1643"
                            x="-56.705"
                            y="145.592"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1641"
                                x="-56.705"
                                y="145.592"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.705"
                                    y="145.592"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1639"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    26
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1649"
                            x="-56.537"
                            y="136.32"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1647"
                                x="-56.537"
                                y="136.32"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.537"
                                    y="136.32"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1645"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    31
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="26"
                            className={`is-clickable ${((carrels[25].status === "RESERVED") || (carrels[25].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.11"
                            y="139.688"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="31"
                            className={`is-clickable ${((carrels[30].status === "RESERVED") || (carrels[30].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.127"
                            y="130.415"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1613-2"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M139.352 44.587l-.05 17.555"
                        />
                    </g>
                    <g id="g1623-6" transform="translate(1196.547 -167.33)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1655"
                            x="-56.663"
                            y="145.592"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1653"
                                x="-56.663"
                                y="145.592"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.663"
                                    y="145.592"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1651"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    25
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1661"
                            x="-56.644"
                            y="136.32"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1659"
                                x="-56.644"
                                y="136.32"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-56.644"
                                    y="136.32"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1657"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    32
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="25"
                            className={`is-clickable ${((carrels[24].status === "RESERVED") || (carrels[24].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.11"
                            y="139.688"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="32"
                            className={`is-clickable ${((carrels[31].status === "RESERVED") || (carrels[31].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-61.127"
                            y="130.415"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1621-6"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M139.352 44.587l-.05 17.555"
                        />
                    </g>
                    <g
                        id="g1555-4-4"
                        transform="matrix(1.0513 0 0 .99477 1094.91 -228.902)"
                    >
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1667"
                            x="166.094"
                            y="198.166"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1665"
                                x="166.094"
                                y="198.166"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="166.094"
                                    y="198.166"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1663"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    24
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="24"
                            className={`is-clickable ${((carrels[23].status === "RESERVED") || (carrels[23].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="160.727"
                            y="191.928"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1553-0-6"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M178.13 191.87l-17.555.06"
                        />
                    </g>
                    <g id="g1555-4" transform="translate(1122.56 -230.033)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1673"
                            x="166.094"
                            y="198.166"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1671"
                                x="166.094"
                                y="198.166"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="166.094"
                                    y="198.166"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1669"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    23
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="23"
                            className={`is-clickable ${((carrels[22].status === "RESERVED") || (carrels[22].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="160.727"
                            y="191.928"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1553-0"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M178.13 191.87l-17.555.06"
                        />
                    </g>
                    <g id="g1591-9" transform="translate(1140.928 -229.969)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1679"
                            x="166.067"
                            y="198.205"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1677"
                                x="166.067"
                                y="198.205"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="166.067"
                                    y="198.205"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1675"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    22
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="22"
                            className={`is-clickable ${((carrels[21].status === "RESERVED") || (carrels[21].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="160.727"
                            y="191.928"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1589-7"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M178.13 191.87l-17.555.06"
                        />
                    </g>
                    <g id="g1585-7" transform="translate(1159.296 -229.97)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1685"
                            x="166.174"
                            y="198.205"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1683"
                                x="166.174"
                                y="198.205"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="166.174"
                                    y="198.205"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1681"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    21
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="21"
                            className={`is-clickable ${((carrels[20].status === "RESERVED") || (carrels[20].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="160.727"
                            y="191.928"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1583-1"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeWidth="1.613"
                            d="M178.13 191.87l-17.555.06"
                        />
                    </g>
                    <g id="g1579-5" transform="translate(1177.664 -229.973)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1691"
                            x="166.07"
                            y="198.166"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1689"
                                x="166.07"
                                y="198.166"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="166.07"
                                    y="198.166"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1687"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    20
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="20"
                            className={`is-clickable ${((carrels[19].status === "RESERVED") || (carrels[19].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="160.727"
                            y="191.928"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1577-7"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M178.13 191.87l-17.555.06"
                        />
                    </g>
                    <g id="g1573-7" transform="translate(1196.032 -229.973)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1697"
                            x="165.923"
                            y="198.167"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1695"
                                x="165.923"
                                y="198.167"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="165.923"
                                    y="198.167"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1693"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    19
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="19"
                            className={`is-clickable ${((carrels[18].status === "RESERVED") || (carrels[18].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="160.727"
                            y="191.928"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1571-7"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M178.13 191.87l-17.555.06"
                        />
                    </g>
                    <g id="g1567-3" transform="translate(1214.4 -229.973)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1703"
                            x="165.907"
                            y="198.166"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1701"
                                x="165.907"
                                y="198.166"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="165.907"
                                    y="198.166"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1699"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    18
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="18"
                            className={`is-clickable ${((carrels[17].status === "RESERVED") || (carrels[17].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="160.727"
                            y="191.928"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1565-5"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M178.13 191.87l-17.555.06"
                        />
                    </g>
                    <g id="g1561-6" transform="translate(1232.768 -229.973)">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1709"
                            x="165.916"
                            y="198.172"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1707"
                                x="165.916"
                                y="198.172"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="165.916"
                                    y="198.172"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1705"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    17
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="17"
                            className={`is-clickable ${((carrels[16].status === "RESERVED") || (carrels[16].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="160.727"
                            y="191.928"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1559-9"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M178.13 191.87l-17.555.06"
                        />
                    </g>
                </g>
                <g
                    id="g2095-4"
                    fill="#c6e48c"
                    fillOpacity="0.996"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(.438 .106) translate(1405.276 15.825)"
                >
                    <g id="g1495-8">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1511"
                            x="-6.953"
                            y="3.4"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1509" x="-6.953" y="3.4" strokeWidth="0.214">
                                <tspan
                                    x="-6.953"
                                    y="3.4"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1507"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    16
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1517"
                            x="-6.893"
                            y="12.644"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1515" x="-6.893" y="12.644" strokeWidth="0.214">
                                <tspan
                                    x="-6.893"
                                    y="12.644"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1513"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    15
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="16"
                            className={`is-clickable ${((carrels[15].status === "RESERVED") || (carrels[15].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-12.119"
                            y="-2.834"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="15"
                            className={`is-clickable ${((carrels[14].status === "RESERVED") || (carrels[14].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-12.102"
                            y="6.439"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1493-9"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M5.3 6.381l-17.555.06"
                        />
                    </g>
                    <g id="g1487-3">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1523"
                            x="-7.256"
                            y="56.455"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1521" x="-7.256" y="56.455" strokeWidth="0.214">
                                <tspan
                                    x="-7.256"
                                    y="56.455"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1519"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    13
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1529"
                            x="-7.342"
                            y="47.189"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1527" x="-7.342" y="47.189" strokeWidth="0.214">
                                <tspan
                                    x="-7.342"
                                    y="47.189"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1525"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    14
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="14"
                            className={`is-clickable ${((carrels[13].status === "RESERVED") || (carrels[13].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-12.493"
                            y="40.945"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="13"
                            className={`is-clickable ${((carrels[12].status === "RESERVED") || (carrels[12].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-12.476"
                            y="50.218"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1485-8"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M4.926 50.16l-17.555.06"
                        />
                    </g>
                    <g id="g1479-8">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1535"
                            x="-6.925"
                            y="91.375"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1533" x="-6.925" y="91.375" strokeWidth="0.214">
                                <tspan
                                    x="-6.925"
                                    y="91.375"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1531"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    12
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1541"
                            x="-6.802"
                            y="100.615"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1539"
                                x="-6.802"
                                y="100.615"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-6.802"
                                    y="100.615"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1537"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    11
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="12"
                            className={`is-clickable ${((carrels[11].status === "RESERVED") || (carrels[11].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-12.119"
                            y="85.098"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="11"
                            className={`is-clickable ${((carrels[10].status === "RESERVED") || (carrels[10].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-12.102"
                            y="94.371"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1477-9"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M5.3 94.313l-17.555.06"
                        />
                    </g>
                    <g id="g1471-6">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1547"
                            x="-5.066"
                            y="144.388"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1545"
                                x="-5.066"
                                y="144.388"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-5.066"
                                    y="144.388"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1543"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    9
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1553"
                            x="-6.923"
                            y="135.113"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1551"
                                x="-6.923"
                                y="135.113"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-6.923"
                                    y="135.113"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1549"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    10
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="10"
                            className={`is-clickable ${((carrels[9].status === "RESERVED") || (carrels[9].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-12.119"
                            y="128.877"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="9"
                            className={`is-clickable ${((carrels[8].status === "RESERVED") || (carrels[8].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-12.102"
                            y="138.149"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1469-5"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M5.3 138.092l-17.555.06"
                        />
                    </g>
                    <g id="g1503-6">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1559"
                            x="-5.499"
                            y="188.54"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan id="tspan1557" x="-5.499" y="188.54" strokeWidth="0.214">
                                <tspan
                                    x="-5.499"
                                    y="188.54"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1555"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    7
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1565"
                            x="-5.485"
                            y="179.267"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1563"
                                x="-5.485"
                                y="179.267"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-5.485"
                                    y="179.267"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1561"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    8
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="8"
                            className={`is-clickable ${((carrels[7].status === "RESERVED") || (carrels[7].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-12.493"
                            y="173.03"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <rect
                            id="7"
                            className={`is-clickable ${((carrels[6].status === "RESERVED") || (carrels[6].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            message="This carrel is close to equipment that may be loud"
                            width="17.278"
                            height="9.217"
                            x="-12.476"
                            y="182.302"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                        />
                        <path
                            id="path1501-5"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M4.926 182.245l-17.555.06"
                        />
                    </g>
                </g>
                <g
                    id="g2073"
                    fill="#c6e48c"
                    fillOpacity="0.996"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(1405.276 15.825)"
                >
                    <g id="g1537-9">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1475"
                            x="-262.756"
                            y="-38.071"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1473"
                                x="-262.756"
                                y="-38.071"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-262.756"
                                    y="-38.071"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1471"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    6
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="6"
                            className={`is-clickable ${((carrels[5].status === "RESERVED") || (carrels[5].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-269.977"
                            y="-42.659"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1535-4"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M-35.035 252.342l-.05 17.555"
                        />
                    </g>
                    <g id="g1519-8">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1481"
                            x="-262.235"
                            y="-81.826"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1479"
                                x="-262.235"
                                y="-81.826"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-262.235"
                                    y="-81.826"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1477"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    4
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1487"
                            x="-262.357"
                            y="-72.592"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1485"
                                x="-262.357"
                                y="-72.592"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-262.357"
                                    y="-72.592"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1483"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    5
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="5"
                            className={`is-clickable ${((carrels[4].status === "RESERVED") || (carrels[4].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-269.775"
                            y="-77.147"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="4"
                            className={`is-clickable ${((carrels[3].status === "RESERVED") || (carrels[3].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-269.792"
                            y="-86.419"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1517-3"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M-78.793 251.881l-.051 17.555"
                        ></path>
                    </g>
                    <g id="g1511-0">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1493"
                            x="-262.672"
                            y="-125.566"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1491"
                                x="-262.672"
                                y="-125.566"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-262.672"
                                    y="-125.566"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1489"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    2
                                </tspan>
                            </tspan>
                        </text>
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1499"
                            x="-262.694"
                            y="-116.332"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1497"
                                x="-262.694"
                                y="-116.332"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-262.694"
                                    y="-116.332"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1495"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    3
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="3"
                            className={`is-clickable ${((carrels[2].status === "RESERVED") || (carrels[2].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-270.425"
                            y="-120.922"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <rect
                            id="2"
                            className={`is-clickable ${((carrels[1].status === "RESERVED") || (carrels[1].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-270.442"
                            y="-130.195"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1509-4"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M-122.572 252.255l-.05 17.555"
                        />
                    </g>
                    <g id="g1543-4">
                        <text
                            xmlSpace="preserve"
                            style={{lineHeight: "0.5"}}
                            id="text1505"
                            x="-262.356"
                            y="-160.264"
                            fill="#000"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.214"
                            fontFamily="sans-serif"
                            fontSize="8.549"
                            fontStyle="normal"
                            fontWeight="normal"
                            letterSpacing="0"
                            transform="rotate(-90)"
                            wordSpacing="0"
                        >
                            <tspan
                                id="tspan1503"
                                x="-262.356"
                                y="-160.264"
                                strokeWidth="0.214"
                            >
                                <tspan
                                    x="-262.356"
                                    y="-160.264"
                                    style={{lineHeight: "0.5"}}
                                    id="tspan1501"
                                    strokeWidth="0.214"
                                    fontSize="5.129"
                                >
                                    1
                                </tspan>
                            </tspan>
                        </text>
                        <rect
                            id="1"
                            className={`is-clickable ${((carrels[0].status === "RESERVED") || (carrels[0].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                            width="17.278"
                            height="9.217"
                            x="-270.305"
                            y="-164.862"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeOpacity="0.418"
                            strokeWidth="0.265"
                            opacity="0.5"
                            ry="0.561"
                            transform="rotate(-89.639)"
                        />
                        <path
                            id="path1541-6"
                            fill="#c6e48c"
                            fillOpacity="0.996"
                            stroke="#040404"
                            strokeDasharray="none"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="4"
                            strokeOpacity="0.418"
                            strokeWidth="1.613"
                            d="M-166.51 251.859l-.051 17.554"
                        />
                    </g>
                </g>
                <rect
                    id="rect1817-3"
                    width="104.775"
                    height="241.829"
                    x="1020.155"
                    y="-97.684"
                    fill="#78bddb"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeLinecap="round"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="1.606"
                    filter="url(#b)"
                    opacity="1"
                    ry="0.561"
                />
                <rect
                    id="rect1819-1"
                    width="103.82"
                    height="358.337"
                    x="-240.675"
                    y="1011.389"
                    fill="#78bddb"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeLinecap="round"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="1.606"
                    filter="url(#c)"
                    opacity="1"
                    ry="0.561"
                    transform="matrix(1.00132 0 0 .99282 1378.025 -1101.988)"
                />
                <rect
                    id="rect1821-7"
                    width="127.22"
                    height="246.209"
                    x="-125.629"
                    y="1125.015"
                    fill="#78bddb"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeLinecap="round"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="1.606"
                    filter="url(#d)"
                    opacity="1"
                    ry="0.561"
                    transform="matrix(1.00196 0 0 .98514 1377.897 -1092.574)"
                />
            </g>
            {Modal}
        </g> : null
    )
}