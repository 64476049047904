import React from "react"
import {Modal, Notification, Button} from "react-bulma-components"
import {client} from "../../../../feathers"

class ConfirmationModalComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            message: null
        };
    }

    onSubmit = () => {
        const carrelsService = client.service("carrels");

        this.setState({
            error: false,
            message: null
        });

        Promise.all([
            carrelsService.patch(this.props.selected, {
                studentNetId: this.props.user.netId
            })
        ]).then(([carrelResult]) => {
            this.onClose();
        }).catch((e) => {
            this.setState({
                error: true,
                message: e.message
            });
        });
    };

    onClose = () => {
        this.setState({
            error: false,
            message: null
        });

        this.props.hide();
    };

    render() {
        return (
            <>
                <Modal show={this.props.isShowing} onClose={this.onClose}>
                    <Modal.Card>
                        <Modal.Card.Head onClose={this.onClose}>
                            <Modal.Card.Title>{this.props.user.netId} selected carrel
                                #{this.props.selected}</Modal.Card.Title>
                        </Modal.Card.Head>
                        <Modal.Card.Body>
                            {(this.state.error && this.state.message) &&
                            <Notification color="danger">{this.state.message}</Notification>
                            }
                            <p>Are you sure?</p>
                            {(this.props.user.carrel && this.props.user.carrel.number) &&
                            <p>By selecting this carrel, you will lose your current carrel,
                                #{this.props.user.carrel.number}</p>}
                            {(this.props.message) && <p>Note: {this.props.message}</p>}
                            <p>You can change your selection after confirmation.</p>
                        </Modal.Card.Body>
                        <Modal.Card.Foot>
                            <Button onClick={this.onClose}>Cancel</Button>
                            <Button color="success" onClick={this.onSubmit}>Confirm</Button>
                        </Modal.Card.Foot>
                    </Modal.Card>
                </Modal>
            </>
        )
    }
}

export default ConfirmationModalComponent
