import React from "react"
import {Columns, Container, Heading, Section} from "react-bulma-components"
import {graphql, StaticQuery} from "gatsby"
import Header from "../../components/Header"
import Page from "../../components/Page"
import Loading from "../../components/Page/Loading"
import CarrelsMap from "../../components/Student/CarrelsMap"
import moment from "moment-timezone"
import {client} from "../../feathers"

class IndexRoute extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            message: null,
            student: null,
            pick: null
        };
    }

    getStudent = () => {
        const studentsService = client.service("students");

        Promise.all([
            studentsService.get(this.props.user.netId, {
                query: {
                    include: true
                }
            })
        ]).then(([studentResult]) => {
            const student = studentResult;

            this.setState({
                student
            });
        }).catch((e) => {
            console.log(e);
        });
    };

    getPicks = () => {
        const studentsService = client.service("students");
        const picksService = client.service("picks");

        this.setState({
            loading: true
        });

        Promise.all([
            studentsService.get(this.props.user.netId)
        ]).then(([studentResult]) => {
            Promise.all([
                picksService.get(studentResult.classYear)
            ]).then(([pickResult]) => {
                const pick = pickResult;

                this.setState({
                    pick
                });
            }).catch((e) => {
                console.log(e);
            });
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    };

    componentDidMount() {
        const studentsService = client.service("students");
        const picksService = client.service("picks");
        const carrelsService = client.service("carrels");

        Promise.all([
            studentsService.get(this.props.user.netId)
        ]).then(([studentResult]) => {
            const student = studentResult;

            this.setState({
                student
            });

            Promise.all([
                picksService.get(student.classYear)
            ]).then(([pickResult]) => {
                const pick = pickResult;

                this.setState({
                    pick
                });
            }).catch((e) => {
                console.log(e);
            });
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            this.setState({
                loading: false
            });
        });

        carrelsService.on("created", () => {
            this.getStudent();
        });
        carrelsService.on("patched", () => {
            this.getStudent();
        });
        carrelsService.on("updated", () => {
            this.getStudent();
        });
        carrelsService.on("removed", () => {
            this.getStudent();
        });

        picksService.on("created", () => this.getPicks());
        picksService.on("patched", () => this.getPicks());
        picksService.on("updated", () => this.getPicks());
        picksService.on("removed", () => this.getPicks());
    }

    render() {
        const query = graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `;

        return (
            <>
                <StaticQuery query={query}
                             render={(data) => <Header title="Home" siteName={data.site.siteMetadata.title}/>}/>
                <Page>
                    {this.state.loading ? <Loading/> : <>
                        {(this.props.user) ? <Section>
                            <Container>
                                <Columns className="is-centered is-vcentered">
                                    <Columns.Column className="has-text-centered">
                                        <Heading className="has-text-weight-normal" renderAs="h3"
                                                 size={3}>Hi, {this.props.user.givenName}</Heading>
                                        {((this.state.student && this.state.student.pickNumber) && this.state.pick && this.state.pick.active) ? <>
                                            <Heading className="has-text-weight-normal" renderAs="h5" size={5} subtitle>Your
                                                carrel pick time is</Heading>
                                            <Heading className="has-text-weight-semibold" renderAs="h3"
                                                     style={{fontSize: "5vh"}}>{moment.tz(this.state.pick.start, moment.tz.guess()).add(this.state.student.pickNumber * this.state.pick.interval, this.state.pick.type.toLowerCase()).format('hh:mm:ss A zz')}</Heading>
                                            <Heading className="has-text-weight-normal" renderAs="h5" size={4}
                                                     subtitle>{moment.tz(this.state.pick.start, moment.tz.guess()).add(this.state.student.pickNumber * this.state.pick.interval, this.state.pick.type.toLowerCase()).format('MMMM Do, YYYY')}</Heading>
                                        </> : <>
                                            <Heading className="has-text-weight-normal" renderAs="h5" size={5} subtitle>You
                                                are not able to pick a carrel at this time</Heading>
                                        </>}
                                    </Columns.Column>
                                </Columns>
                            </Container>
                        </Section> : <Section>
                            <Container>
                                <p><em>Your user profile could not be found</em></p>
                            </Container>
                        </Section>}
                        {(this.state.pick && this.state.pick.active) && <Section>
                            <Container>
                                <Columns className="is-centered is-vcentered">
                                    <Columns.Column className="has-text-centered">
                                        <Heading className="has-text-weight-normal" renderAs="h5"
                                                 size={5}>Selection will end</Heading>
                                        <Heading className="has-text-weight-semibold" renderAs="h3"
                                                 size={3} style={{fontSize: "3vh"}}
                                                 subtitle>{moment.tz(this.state.pick.end, moment.tz.guess()).format('MMMM Do, YYYY')} at {moment.tz(this.state.pick.end, moment.tz.guess()).format('hh:mm:ss A zz')}</Heading>

                                    </Columns.Column>
                                </Columns>
                            </Container>
                        </Section>}
                        {(this.state.student && this.state.student.carrel && this.state.student.carrel.number) &&
                        <Section>
                            <Container>
                                <Columns className="is-centered is-vcentered">
                                    <Columns.Column className="has-text-centered">
                                        <Heading className="has-text-weight-normal" renderAs="h5" size={5} subtitle>The
                                            carrel you have currently selected is</Heading>
                                        <Heading className="has-text-weight-semibold" renderAs="h5"
                                                 style={{fontSize: "3rem"}}>{this.state.student.carrel.number}</Heading>
                                    </Columns.Column>
                                </Columns>
                            </Container>
                        </Section>}
                        {(this.props.user) && <Section>
                            <Container>
                                <Columns className="is-centered is-vcentered">
                                    <Columns.Column>
                                        <CarrelsMap user={this.props.user}/>
                                    </Columns.Column>
                                </Columns>
                            </Container>
                        </Section>}
                    </>}
                </Page>
            </>
        )
    }
}

export default IndexRoute