import React from "react"
import {Columns} from "react-bulma-components"
import Loading from "../Page/Loading"
import Navigation from "./CarrelsMap/Navigation"
import Map from "./CarrelsMap/Map"
import {client} from "../../feathers"

class CarrelsMapComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            floor: 1,
            carrels: []
        };
    }

    getCarrels = async () => {
        const carrelsService = client.service("carrels");
        let carrels = [];
        let initialResult = await carrelsService.find().catch((e) => {
            console.log(e);
        });
        let limit = initialResult.limit;
        let total = initialResult.total;
        let pages = Math.ceil(total / limit);

        for (let i = 0; i < pages; i++) {
            let carrelsResult = await carrelsService.find({
                query: {
                    $limit: limit,
                    $skip: i * limit,
                    $sort: {
                        number: 1
                    }
                }
            }).catch((e) => {
                console.log(e);
            });

            carrels = carrels.concat(carrelsResult.data);
        }

        return carrels;
    };

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    componentDidMount() {
        const carrelsService = client.service("carrels");

        this.getCarrels().then((carrels) => {
            this.setState({
                carrels
            });
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            this.setState({
                loading: false
            });
        });

        carrelsService.on("created", () => this.getCarrels().then((carrels) => {
            this.setState({
                carrels
            });
        }));
        carrelsService.on("patched", () => this.getCarrels().then((carrels) => {
            this.setState({
                carrels
            });
        }));
        carrelsService.on("updated", () => this.getCarrels().then((carrels) => {
            this.setState({
                carrels
            });
        }));
        carrelsService.on("removed", () => this.getCarrels().then((carrels) => {
            this.setState({
                carrels
            });
        }));
    }

    render() {
        return (
            (this.state.loading) ? <Loading/> : <>
                {(this.state.carrels && this.state.carrels.length > 0) && <>
                    <Columns className="justify-center">
                        <Columns.Column narrow>
                            <Navigation floor={this.state.floor} onChange={this.onChange}/>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Map floor={this.state.floor} user={this.props.user} carrels={this.state.carrels}/>
                        </Columns.Column>
                    </Columns>
                </>}
            </>
        )
    }
}

export default CarrelsMapComponent